import React, { useState, useEffect, useRef } from 'react';
import './ClueInputTextBox.css';

interface ClueInputProps {
  onClueTextChanged: (code: string) => void;
  value: string;
  flashRed: boolean;
  disabled: boolean;
  onNumberTypedChanged: (numValue: string) => void;
}

const ClueInput: React.FC<ClueInputProps> = ({ onClueTextChanged, flashRed, value, disabled,onNumberTypedChanged }) => {
  const inputRef = useRef<HTMLInputElement>(null); // Create a reference to the input element
  const [numberValue, setNumberValue] = useState<string>(''); // State to store the digit 1-9

  // Focus the input field when the component is mounted
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    onNumberTypedChanged(numberValue);
  }, [numberValue])

  const handleClueTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const code = e.target.value;
    
    // Extract alphabetic characters and digits 1-9 only
    const alphaValue = code.replace(/[^a-zA-Z]/g, '');
    const numberMatch = code.match(/[1-9]/);

    // Update the state with the extracted number (if any)
    if (numberMatch) {
      setNumberValue(numberMatch[0]); // Store the first digit found
    }

    // Update the input value with only alphabetic characters
    onClueTextChanged(alphaValue);
  };

  const placeholderText = disabled ? "Standby..." : "Type clue here:";

  return (
    <div className="modal">
      <div className="modal-content">
        <input
          type="text"
          value={value}
          onChange={(e) => {
            // Pass the event to the modified handleClueTextChange
            handleClueTextChange(e);
          }}
          placeholder={placeholderText}
          className={`clue-box-input ${flashRed ? 'redFlash' : disabled ? 'disabled' : ""}`}
          ref={inputRef}
          required
          disabled={disabled}
        />
        <br />
        {/* Display the stored number value */}
        {/* <div>Stored Digit: {numberValue}</div> */}
      </div>
    </div>
  );
};

export default ClueInput;
