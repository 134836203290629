import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PrettyButton from '../components/PrettyButton';
import PlayerSelector from '../components/PlayerSelector';
import ShareButton from '../components/ShareButton';
import { Player,GameStatus, Chat } from '../types';
import './Lobby.css'
import Header from '../components/Header';
import { useWebSocket } from '../context/WebSocketContext';
import { GetOrSetPlayerId } from '../utilities/misc';
import PrettyChatbox from '../components/PrettyChatbox';


const Lobby: React.FC = () => {
  const { gameCode } = useParams<{ gameCode: string }>();

  // State to hold players and the current player
  const [players, setPlayers] = useState<Player[]>([]);
  const [currentPlayer, setCurrentPlayer] = useState<Player | null>(null);
  const [lobbyStatus, setLobbyStatus] = useState<string>("");
  const [registeredPlayers, setRegisteredPlayers] = useState<Player[]>([]);
  const [startClicked, setStartClicked] = useState<boolean>(false);
  const { socket } = useWebSocket();
  const navigate = useNavigate();

  useEffect(() => {
    if (socket) {
      const handleMessage = (event: any) => {
        const message = JSON.parse(event.data);
      //console.log("Lobby message.type = ", message.type);
  
        if (message.type === 'game_created') {
          setRegisteredPlayers(message.registeredPlayers);
        }
      };
  
      socket.addEventListener('message', handleMessage);
  
      return () => {
        socket.removeEventListener('message', handleMessage); // Cleanup listener on unmount
      };
    }
  }, [socket]);
  
  useEffect(() => { 
    const registeredPlayerIDs = registeredPlayers.map(r => r.id);
  
    if (registeredPlayers && currentPlayer) {
      if (registeredPlayerIDs.some(x => x === currentPlayer.id)) {
        navigate(`/game/${gameCode}`);
      } else {
        alert("Sorry, this game has already started.");
        navigate(`/`);
      }
    }
  }, [registeredPlayers]);

  const startButtonEnabled = () => {
    const registeredPlayersC = registeredPlayersCount();

    return registeredPlayersC === 4;
  }

  const registeredPlayersCount = () : number => {
    const registeredPlayersCountLocal = players.filter(r => r.role && r.team);
    return registeredPlayersCountLocal.length;
  }

  //create new game
  const onStartClicked = () => {
    if (socket) {
      socket.send(JSON.stringify({
        type: 'start_game',
        gameCode,
        playerId: GetOrSetPlayerId()
      }));
      setStartClicked(true);

    } else {
    //console.log('WebSocket connection not available');
    }
  };

  // Check if gameCode is undefined, and handle that case (e.g., show an error)
  if (!gameCode) {
    return <div>Error: Game code not found!</div>;
  }

  const GenerateLobbyContent = () => {
    return lobbyStatus === "" ? 
    (<div>
      <div className='lobbyHeader'>Game Code: {gameCode}</div>
            <div className='shareContainer'>
              <ShareButton gameCode={gameCode}></ShareButton>
            </div>
        
            {/* Pass the gameCode, setPlayers, and setCurrentPlayer to PlayerSelector */}
            <PlayerSelector onPlayersChange={setPlayers} onCurrentPlayerChange={setCurrentPlayer} onLobbyStatusChange={setLobbyStatus} gameCode={gameCode} />
        
            <div className='buttonContainer'>
              <PrettyButton onClick={onStartClicked} text="Start Game" disabled={!startButtonEnabled()}></PrettyButton>
            </div>
        
            <div className='waitingTextLine' hidden={startButtonEnabled()}>
              Waiting for {4 - registeredPlayersCount()} more player{4 - registeredPlayersCount() > 1 ? 's' : ''} to ready up...
            </div>
    </div>

    ) :
    (<div className='invalidLobby'>{lobbyStatus}</div>)
  }

  return  (
    <div className='lobbyPage'>
      <Header></Header>
      {GenerateLobbyContent()}
    </div>
  )
};

export default Lobby;
