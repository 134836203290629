import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate(`/`);
  };

  return (
    <div>
      <img 
        src={`${process.env.PUBLIC_URL}/ssds-banner.png`} 
        alt="Same Sport, Different Stadium" 
        onClick={goHome} 
        className="headerImage" 
      />
      <hr className="separator" />
    </div>
  );
};

export default Header;
