import React from 'react';
import PrettyButton from './PrettyButton';

interface ShareButtonProps {
  gameCode: string;
}

const ShareButton: React.FC<ShareButtonProps> = ({ gameCode }) => {

  const isLocalhost = window.location.hostname === 'localhost';

  const rootURL = isLocalhost ? "http://localhost:3000" : "https://samesportdifferentstadium.com"
  const combinedURL = `${rootURL}/#/lobby/${gameCode}`

  const handleShare = async () => {
    if (navigator.share) {
      try {
        const shareData = {
          title: 'Same Sport, Different Stadium',
          text: `Join my game of Same Sport, Different Stadium with this code: ${gameCode}`,
          url: combinedURL,
        };

        await navigator.share(shareData);
        // console.log('Shared successfully');
        // alert("success somehow")
      } catch (error) {
        alert("Error sharing link")
        // console.error('Error sharing:', error);
      }
    } 

    else {
      navigator.clipboard.writeText(combinedURL)
        .then(() => alert('Share link copied to clipboard!'))
        .catch((error) => console.error('Error copying link:', error));
    }
  };

  return (
    <PrettyButton text="Share" onClick={handleShare} shareButton={true} />
  );
};

export default ShareButton;
