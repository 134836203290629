import React, { useState, useEffect } from 'react';
import { useWebSocket } from '../context/WebSocketContext'; // Custom WebSocket context
import { Team, Role, Player, GameStatus, Chat } from '../types'; // Ensure you define these types or enums
import './PlayerSelector.css'
import { useNavigate, useParams } from 'react-router-dom';
import { GetOrSetPlayerId } from '../utilities/misc';
import { playTileClickSound } from '../utilities/misc';
import PrettyChatbox from './PrettyChatbox';


interface PlayerSelectorProps {
  onPlayersChange: (players: Player[]) => void;
  onCurrentPlayerChange: (player: Player) => void;
  onLobbyStatusChange: (lobbyStatus: string) => void;
  gameCode: string; // Ensure gameCode is passed as a prop
}

const PlayerSelector: React.FC<PlayerSelectorProps> = ({ onPlayersChange, onCurrentPlayerChange, onLobbyStatusChange , gameCode }) => {
  const [players, setPlayers] = useState<Player[]>([]);
  const [currentPlayer, setCurrentPlayer] = useState<Player | null>(null);
  const { socket } = useWebSocket(); // WebSocket connection
  const [playerId, setPlayerID] = useState<string>();
  const [lobbyStatus, setLobbyStatus] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    const newID = GetOrSetPlayerId();
    setPlayerID(newID);
  },[]);

  useEffect(() => {
    if (!currentPlayer && playerId)
    {
      const newPlayer: Player = { id: playerId};
      setCurrentPlayer(newPlayer);
    }

  },[playerId]);

  useEffect(() => {
    if (currentPlayer)
    {
      onCurrentPlayerChange(currentPlayer)
    }

  },[currentPlayer]);

  useEffect(() => {
    onLobbyStatusChange(lobbyStatus)
  },[lobbyStatus]);


  useEffect(() => {
    // console.log("player change")
    // console.log(JSON.stringify(players))
    onPlayersChange(players)
  },[players]);

  useEffect(() => {
    const newID = GetOrSetPlayerId();
    setPlayerID(newID);
  
    const joinLobby = () => {
      if (socket && socket.readyState === 1) {
        socket.send(JSON.stringify({
          type: 'join_lobby',
          playerId: newID,
          gameCode,
        }));
      }
    };

    const startJoinLobbyInterval = () => {
      // console.log("startfetchboardinterval")
      return setInterval(() => {
        joinLobby();
      }, 10000); 
    };

  
    const handleMessage = (event: any) => {
      const message = JSON.parse(event.data);
  
      if (message.type === 'joined_lobby') {
        // console.log('join lobby')
        const curPlayer = (message.players as Player[]).find(p => p.id === newID);
        if (curPlayer) {
          setCurrentPlayer(curPlayer);
        }
        setPlayers(message.players || []);
      } else if (message.type === 'role_selected') {
        setPlayers(message.players || []); 
      } else if (message.type === 'error' && message.message === "Game in progress") {
        navigate(`/game/${gameCode}`);
      } else if (message.type === 'error') {
        setLobbyStatus(message.message);
        console.error('Error:', message.message);
      }
    };
  
    const setupConnection = () => {
      // console.log("running setup connection")
      if (socket) {
        if (socket.readyState === 1) {
          joinLobby();
          socket.addEventListener('message', handleMessage);
        } else if (socket.readyState === 0) {  // If socket is connecting, wait for it to open
          socket.onopen = () => {
            joinLobby();
            socket.addEventListener('message', handleMessage);
          };
          socket.onerror = () => {
            console.error('WebSocket connection failed, retrying...');
            setTimeout(setupConnection, 1000);  // Retry every second if there's an error
          };
        } else {
          // WebSocket is not initialized or closed, attempt to reconnect
          setTimeout(setupConnection, 1000);
        }
      } else {
        // window.location.reload();
        // console.error('WebSocket is not initialized.');
        // alert("Could not connect to server, please try rejoining.")
        // navigate("/");
      }
    };
  
    setupConnection();

    const intervalFetch = startJoinLobbyInterval()
  
    return () => {
      if (socket) {
        socket.removeEventListener('message', handleMessage);
        clearInterval(intervalFetch)

      }
    };
  }, [socket, gameCode, onPlayersChange]);
  


  const handleQuadrantClick = (teamParam: Team, roleParam: Role, isTaken: boolean) => {
    let playerName: string | null = null;

    const samePlayer = players.find(x => x.role === roleParam && x.team === teamParam)?.id === currentPlayer?.id && isTaken

    if (isTaken && !samePlayer)
    {
      // console.log("stopit")
      return;
    }
  
    playTileClickSound();

    // If it's not a deselect, prompt for player name
    if (!isTaken) {
      playerName = prompt('Enter your name:');
      if (!playerName) return; // Abort if no name provided
    }
  
    const team = samePlayer ? undefined : teamParam;
    const role = samePlayer ? undefined : roleParam;
  
      if (socket && socket.readyState === 1) {
        socket.send(JSON.stringify({
          type: 'select_role',
          gameCode,
          playerId,
          playerName,
          team,
          role
        }));
    
        // Set the current player for display purposes
        const newPlayer: Player = { id: playerId || "", name: playerName || "", team, role };
        setCurrentPlayer(newPlayer);
        onCurrentPlayerChange(newPlayer); // Notify parent component
      } else {
        console.error('WebSocket is not open yet. Cannot send message.');
      }
    
  };
  


  return (
    <div>
      <div className="quadrant-container">
        {['red-spymaster', 'red-guesser', 'blue-spymaster', 'blue-guesser'].map((key) => {
          const [team, role] = key.split('-');
          const isTaken = players.some(player => player.team === team && player.role === role);
          const displayText = isTaken ? players.find(player => player.team === team && player.role === role)?.name : 'Available';

          return (
            <div
              key={key}
              className={`quadrant ${team} ${isTaken ? 'disabled' : ''}`}
              onClick={() => handleQuadrantClick(team as Team, role as Role, isTaken)}
            >
              {`${team.charAt(0).toUpperCase() + team.slice(1)} ${role.charAt(0).toUpperCase() + role.slice(1)}`}<br />
              ({displayText})
            </div>

          );
        })}
      </div>
    </div>
  );
};

export default PlayerSelector;
