// Enums for Team and Role
export enum Team {
  Red = 'red',
  Blue = 'blue'
}

export enum Role {
  Spymaster = 'spymaster',
  Guesser = 'guesser'
}

export enum GameStatus {
  Lobby = 'lobby',
  Active = 'game',
  BlueWin = 'blue-win',
  RedWin = 'red-win',
  Finished = 'finished'
}

export enum CardType {
  Blue = 'blue',
  Red = 'red',
  Bystander = 'bystander',
  Assassin = 'assassin'
}

export interface GameBoard {
  gameBoard: any;
}


// Interface for a Player
export interface Player {
  id: string; // Unique player ID
  name?: string; // Player's name
  team?: Team;
  role?: Role;
}

// Interface for a Lobby
export interface Lobby {
  players: Player[];
  gameBoard: Card[];
  gameStatus: GameStatus;
  lobbyId: string;
  gameCode: string;
  currentTurn: CurrentTurn;
  currentClue?: Clue;
  listName?: string;
  wordCount?: number;
  assassinClicked: boolean;
  chat: Chat;
}

export interface Card {
  word: string;
  revealed: boolean;
  revealColor?: CardType;
}

export interface CurrentTurn {
  team: Team;
  role?: Role;
  clicksRemaining: number;
  teamSwitch: boolean;
}

export interface Clue {
  word: string;
  maxClicks: number;
}

export interface Message {
  player: Player;
  text: string;
  timestamp: Date;
}

export interface Chat {
  messages: Message[]
}

export * from './types';